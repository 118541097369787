.title {
  font-family: 'AndaleMono', monospace;
  font-size: 2.5rem;
  font-weight: 900;
  line-height: 3.5rem;
  margin-top: 0;
  margin-bottom: 2.625rem;
  text-rendering: optimizeLegibility;
}
.title a {
  color: rgba(255, 255, 255, 0.9);
  text-decoration: none;
}

.articlesList,
.articlesList > li {
  list-style: none;
  padding: 0;
  margin: 0 0 2.625rem;;
}

.footer {
  margin-top: 7rem;
  margin-bottom: 0.5rem;
}