.container {
  margin: 0 auto;
  max-width: 42rem;
  padding: 2.625rem 1.3125rem;
}

.title {
  font-family: 'AndaleMono', monospace;
  font-size: 1.44rem;
  font-weight: 900;
  line-height: 1.1;
  margin-top: 0;
  margin-bottom: 0;
  text-rendering: optimizeLegibility;
}
.title > a {
  color: var(--primary-color-light);
  text-decoration: none;
}
